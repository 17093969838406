import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { addAdminNotice, getAdminNotice } from "../../../redux/admin/saga";
import { adminNoticeSelector } from "../../../redux/admin/selector";

export function Notice() {
  const dispatch = useDispatch();
  const notice = useSelector(adminNoticeSelector);
  const [newNotice, setNewNotice] = useState("");

  useEffect(() => {
    dispatch(getAdminNotice());
  }, [dispatch]);

  useEffect(() => {
    setNewNotice(notice);
  }, [notice]);

  const handleChangeNotice = (event) => {
    setNewNotice(event.target.value);
  };

  const modifyNotice = () => {
    if (newNotice.trim()) {
      dispatch(
        addAdminNotice({
          content: newNotice,
        })
      );
    }
  };

  return (
    <>
      <TextField
        label="공지사항 수정"
        fullWidth
        multiline
        value={newNotice}
        sx={{ margin: "25px 0 0 0" }}
        onChange={handleChangeNotice}
      />
      <Button
        variant="contained"
        component="label"
        sx={{ margin: "20px 20px 20px 0", float: "right" }}
        onClick={modifyNotice}
      >
        저장
      </Button>
    </>
  );
}
