import React from "react";
import { useNavigate } from "react-router-dom";

const RuleDetail = () => {
    const navigate = useNavigate();
    const handleClickNav = () => {
        navigate(-1);
    }

    return (
        <>
            <div className="rule-detail">
                <div className="sub-header scrolled">
                    <button className="back" onClick={handleClickNav} />
                    <div className="detail-title">1. The Game, Player Conduct anananana</div>
                </div>
                <div className="content-area">
                    <div className="content">골프채와 공영국 R&A와 미국골프협회는 언제든지 골프채, 공과 기타 용구에 관한 규칙을 개정하거나 그에 관한 해석을 제정하거나 변경할 권한을 보유한다.

                        5 - 1. 총칙 <br/><br/>
                        공이 명백히 찢어졌거나, 금이 갔거나, 모양이 바뀌었으면 그 공은 경기에 부적합한 것이다. 단순히 흙이나 기타 물건이 붙어 있거나, 색깔이 변한 것만으로는 경기에 부적합한 공으로 보지 않는다.<br/>
                        경기자는 경기 중에 공이 경기에 부적합하다고 생각되면 그것을 확인하기 위해 벌 없이 집어 올릴 수 있다. 그러나 집어 올리기 전에 매치 플레이에서는 상대방, 스트로크 플레이에서는 마커나 동반 경기자에게 그 의사를 통고하고 공 위치에 마크한 후 집어 올려닦을 수 없음) 조사하고 상대방이나 마커나 동반 경기자에게도 공을 조사할 기회를 줘야 한다.<br/>
                        이러한 절차를 밟지 않았을 때는 경기자는 1벌타를 받아야 한다. 공이 경기에 부적합하다고 결정되면 경기자는 다른 공을 원구가 있던 곳에 놓을 수 있지만 공이 경기에 지장이 없으면 원구를 제자리에 놓아야 한다. 친 공이 조각이 났을 때는 그 타를 취소하고 벌 없이 친 곳에서 다시 쳐야 한다제20조 5 참조).
                    </div>

                </div>
            </div>
        </>
    );
}

export default RuleDetail;
