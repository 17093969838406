import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { addAdminFitting } from "../../../redux/admin/saga";
import { setFittingShopLayer } from "../../../redux/admin/slice";
import { adminFittingSelector } from "../../../redux/admin/selector";

export function FittingLayer() {
  const dispatch = useDispatch();
  const { layer } = useSelector(adminFittingSelector);
  const { field, isNew } = layer;
  const [imageFile, setImageFile] = useState();

  const handleClose = () => {
    dispatch(
      setFittingShopLayer({
        show: false,
        field: {},
      })
    );
  };

  const handleChange = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      dispatch(
        setFittingShopLayer({
          field: {
            ...field,
            imageUrl: reader.result,
          },
        })
      );
    };
    setImageFile(e.target.files[0]);
  };
  const handleChangeField = (name) => (event) => {
    dispatch(
      setFittingShopLayer({
        field: {
          ...field,
          [name]: event.target.value,
        },
      })
    );
  };

  const handleClickSave = () => {
    dispatch(
      addAdminFitting({
        imageFile,
        ...field,
        isNew,
        callback: handleClose,
      })
    );
  };

  return (
    <>
      <Dialog open={true} onClose={handleClose} sx={{ minWidth: 400 }}>
        <DialogTitle>{isNew ? "피팅샵 추가" : "피팅샵 변경"}</DialogTitle>
        <FormControl sx={{ width: 400 }}>
          <DialogContent sx={{ pb: 0 }}>
            <div>
              {field.imageUrl && <img width="350" src={field.imageUrl} />}

              <Button
                variant="contained"
                component="label"
                sx={{ margin: "0px 20px 20px 0" }}
              >
                {isNew ? "이미지 추가" : "이미지 변경"}
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleChange}
                />
              </Button>
            </div>

            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">지역</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={field.city || ""}
                  label="지역"
                  onChange={handleChangeField("city")}
                >
                 <MenuItem value={"서울"}>서울</MenuItem>
                <MenuItem value={"경기"}>경기</MenuItem>
                <MenuItem value={"강원"}>강원</MenuItem>
                <MenuItem value={"충청"}>충청</MenuItem>
                <MenuItem value={"전라"}>전라</MenuItem>
                <MenuItem value={"경상"}>경상</MenuItem>
                <MenuItem value={"제주"}>제주</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField
              label="상호명"
              sx={{ marginTop: 1, display: "block" }}
              onChange={handleChangeField("name")}
              value={field.name || ""}
              fullWidth
            />
            <TextField
              label="한 줄 설명"
              sx={{ marginTop: 1, display: "block" }}
              value={field.description || ""}
              onChange={handleChangeField("description")}
              fullWidth
            />
            <TextField
              label="주소"
              sx={{ marginTop: 1, display: "block" }}
              value={field.address || ""}
              onChange={handleChangeField("address")}
              fullWidth
            />
            <TextField
              label="전화번호"
              sx={{ marginTop: 1, display: "block" }}
              value={field.phoneNumber || ""}
              onChange={handleChangeField("phoneNumber")}
              fullWidth
            />
            <TextField
              label="상세설명"
              sx={{ marginTop: 1, display: "block" }}
              value={field.content || ""}
              onChange={handleChangeField("content")}
              multiline
              fullWidth
            />
          </DialogContent>
        </FormControl>
        <DialogActions>
          <Button onClick={handleClose}>취소</Button>
          <Button onClick={handleClickSave} autoFocus>
            {isNew ? "추가" : "변경"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
