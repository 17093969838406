import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  board: {
    notice: "",
    posts: [],
  },
  logo: {
    imageUrl: "",
  },
  background: {
    color: "",
  },
  newsBanner: {
    imageUrl: "",
    link: "",
  },
  fitting: {
    fittingShop: {},
    cities: [],
    layer: {
      show: false,
      isNew: false,
      field: {},
    },
  },
};

const adminState = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminLogoImage: (state, { payload }) => {
      state.logo.imageUrl = payload;
    },
    setAdminBackgroundColor: (state, { payload }) => {
      state.background.color = payload;
    },
    setAdminNotice: (state, { payload }) => {
      state.board.notice = payload;
    },
    setAdminPosts: (state, { payload }) => {
    state.board.posts = payload;
    },
    setAdminNewsBanner: (state, { payload }) => {
      state.newsBanner = payload;
    },
    appendAdminFittingShop: (state, { payload }) => {
      state.fitting.fittingShop = {
        ...state.fitting.fittingShop,
        ...payload,
      };
    },
    setAdminFittingCities: (state, { payload }) => {
      state.fitting.cities = payload;
    },
    setFittingShopLayer: (state, { payload }) => {
      state.fitting.layer = {
        ...state.fitting.layer,
        ...payload,
      };
    },
  },
});

export const {
  setAdminLogoImage,
  setAdminBackgroundColor,
  setAdminNotice,
  setAdminPosts,
  appendAdminFittingShop,
  setAdminFittingCities,
  setAdminNewsBanner,
  setFittingShopLayer,
} = adminState.actions;
export const adminReducer = adminState.reducer;
