import React, { useEffect, useRef } from "react";
import Navigator from "../../components/navigator";
import { useNavigate } from "react-router-dom";
import Area from "./Area";
import { useDispatch, useSelector } from "react-redux";
import { getCities } from "../../redux/service/saga";
import { fittingSelector } from "../../redux/service/selector";
import { useIsHistoryPop } from "../../hooks";

const Fitting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isHistoryPop = useIsHistoryPop();
  const { cities } = useSelector(fittingSelector);
  const handleClickSearch = () => {
    navigate("/fitting-search");
  };

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  useEffect(() => {
    if (!isHistoryPop) {
      document.documentElement.scrollTop = 0;
    }
  }, [isHistoryPop]);

  return (
    <>
      <h1 className="title">피팅</h1>
      <div className="fitting">
        <div className="search-mode">
          <input
            type="text"
            placeholder="상호명 검색"
            onClick={handleClickSearch}
          />
          <div className="icon">
            <div className="search">
              <i className="fas fa-search"></i>
            </div>
          </div>
        </div>
        <div className="area-list">
          {cities.map((city, index) => (
            <Area city={city} key={city}/>
          ))}
        </div>
      </div>
      <Navigator index={1} />
    </>
  );
};

export default Fitting;
