import React, { useEffect } from "react";
import { Notice } from "./Notice";
import { Post } from "./Post";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { adminPostsSelector } from "../../../redux/admin/selector";
import { useDispatch } from "react-redux";
import { getAdminPosts } from "../../../redux/admin/saga";

export default function AdminBoard() {
  const posts = useSelector(adminPostsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminPosts());
  }, [dispatch]);
  return (
    <div style={{ margin: "10px" }}>
      <Typography variant="h6">공지사항</Typography>
      <Notice />
      <Typography variant="h6" sx={{ marginTop: 10 }}>
        그늘집
      </Typography>
      {posts.map((post) => {
        return <Post {...post} id={post.id} />;
      })}
    </div>
  );
}
