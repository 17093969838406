import React, { useEffect } from "react";
import Navigator from "../../components/navigator";
import BoardItem from "./board-item";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotice, getPosts } from "../../redux/service/saga";
import { noticeSelector, postsSelector } from "../../redux/service/selector";

const Board = () => {
  const navigate = useNavigate();
  const notice = useSelector(noticeSelector);
  const posts = useSelector(postsSelector);
  const handleClickWrite = () => {
    navigate("/board-write");
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotice());
    dispatch(getPosts());
  }, [dispatch]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      <h1 className="title">그늘집</h1>
      <div className="board">
        <div className="notice">
          <div className="title">공지사항</div>
          <div className="content">
            {notice?.split("\n").map((txt) => {
              return (
                <>
                  {txt}
                  <br />
                </>
              );
            })}
          </div>
        </div>
        <div className="board-list">
          {posts.map((post) => {
            return <BoardItem key={post.id} {...post} />;
          })}
        </div>
        <button className="board-add" onClick={handleClickWrite} />
      </div>
      <Navigator index={2} />
    </>
  );
};

export default Board;
