import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Input from "@mui/material/Input";

import { useDispatch, useSelector } from "react-redux";
import {
  addAdminBackground,
  getAdminBackground,
} from "../../../redux/admin/saga";
import { adminBackgroundSelector } from "../../../redux/admin/selector";

const AdminBackground = () => {
  const dispatch = useDispatch();
  const [color, setColor] = useState("");
  const background = useSelector(adminBackgroundSelector);

  const handleClickSave = () => {
    dispatch(
      addAdminBackground({
        color,
      })
    );
  };

  useEffect(() => {
    dispatch(getAdminBackground());
  }, [dispatch]);

  useEffect(() => {
    setColor(background.color);
  }, [background.color]);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650, marginTop: 2 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: "20%" }}>홈 백그라운드 컬러</TableCell>
              <TableCell>
                <Input
                  placeholder="#000000 형식으로 색상코드를 입력해주세요."
                  fullWidth
                  value={color}
                  onChange={(event) => {
                    setColor(event.target.value);
                  }}
                />
              </TableCell>
            </TableRow>{" "}
            <TableRow sx={{ border: 0 }}>
              <TableCell colSpan={2} align="right" sx={{ border: 0 }}>
                <Button
                  variant="contained"
                  component="label"
                  onClick={handleClickSave}
                >
                  저장
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdminBackground;
