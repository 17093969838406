import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFittings } from "../../redux/service/saga";
import { fittingSelector } from "../../redux/service/selector";
import {
  setOpenedCities,
  setFittingCitiesScroll,
} from "../../redux/service/slice";
import { useIsHistoryPop } from "../../hooks";
const Area = ({ city }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isHistoryPop = useIsHistoryPop();
  const { fittingShop, openedCities, citiesScroll } =
    useSelector(fittingSelector);

  const savedScroll = useRef(citiesScroll[city]);
  const scrollRef = useRef(null);
  const isOpened = openedCities.indexOf(city) !== -1;
  const handleClickDetail = (id) => () => {
    navigate(`/fitting-detail/${id}`);
  };

  const handleClickFold = () => {
    if (isOpened) {
      dispatch(
        setOpenedCities(
          openedCities.filter((openedCity) => city !== openedCity)
        )
      );
    } else {
      dispatch(setOpenedCities([...openedCities, city]));
    }
  };

  const handleScroll = (e) => {
    if (citiesScroll[city] !== e.target.scrollLeft) {
      dispatch(
        setFittingCitiesScroll({
          [city]: e.target.scrollLeft,
        })
      );
    }
  };

  useEffect(() => {
    if (isOpened) {
      dispatch(getFittings(city));
    }
  }, [dispatch, city, isOpened]);

  useEffect(() => {
    if (isHistoryPop && scrollRef.current) {
      scrollRef.current.scrollLeft = savedScroll?.current || 0;
    }
  }, [isHistoryPop]);

  return (
    <>
      <div className="area" onClick={handleClickFold}>
        {city}
        <button className={isOpened ? "unfold" : "fold"} />
      </div>
      {isOpened && (
        <div className="place-list" onScroll={handleScroll} ref={scrollRef}>
          {fittingShop[city]?.map((shop) => {
            return (
              <div
                className="place"
                onClick={handleClickDetail(shop.id)}
                key={shop.id}
              >
                <div>
                  <img
                    src={shop.imageUrl}
                    alt="Avatar"
                    style={{ width: "100%", height: 100 }}
                  />
                </div>
                <div className="place-desc">
                  <div className="place-name">{shop.name}</div>
                  <div className="place-line">{shop.description}</div>
                  <div className="place-address">{shop.address}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Area;
