import React from "react";
import { useNavigate } from "react-router-dom";
import Navigator from "../../components/navigator";

const Help = () => {
  const navigate = useNavigate();
  const handleClickNav = () => {
    navigate(-1);
  };

  const handleClickHelp1 = () => {
    window.location.href = "mailto:report@hole1n.com";
  };

  const handleClickHelp2 = () => {
    window.location.href = "mailto:team@hole1n.com";
  };

  return (
    <>
      <div className="help">
        <div className="sub-header scrolled">
          <button className="back" onClick={handleClickNav} />
          <div className="detail-title">고객센터</div>
        </div>
        <div className="contents">
          <div className="item-list">
            <div className="item-title">문의</div>
            <div className="item" onClick={handleClickHelp1}>
              <span>개발자 문의</span>
              <button className="btn" />
            </div>
            <div className="item" onClick={handleClickHelp2}>
              <span>입점 문의</span>
              <button className="btn" />
            </div>
          </div>
          <div className="item-list">
            <div className="item-title">이용약관</div>
            <div
              className="item"
              onClick={() => {
                navigate("/help-detail/1");
              }}
            >
              <span>서비스 이용약관</span>
              <button className="btn" />
            </div>
            <div
              className="item"
              onClick={() => {
                navigate("/help-detail/2");
              }}
            >
              <span>개인정보 처리방침</span>
              <button className="btn" />
            </div>
          </div>
        </div>
      </div>
      <Navigator index={0} />
    </>
  );
};

export default Help;
