import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchFittings } from "../../../redux/service/saga";
import { fittingSearchSelector } from "../../../redux/service/selector";
import { useDispatch, useSelector } from "react-redux";
import { setFittingSearchList } from "../../../redux/service/slice";

const FittingSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [query, setQuery] = useState("");
  const { searchList } = useSelector(fittingSearchSelector);
  const handleClickClose = () => {
    dispatch(setFittingSearchList([]));
    navigate("/fitting", {
      replace: true,
    });
  };

  const handleClickDetail = (id) => () => {
    navigate(`/fitting-detail/${id}`);
  };

  const handleClickClear = () => {
    dispatch(setFittingSearchList([]));
    setQuery("");
  };
  useEffect(() => {
    useRef.current?.click();
    useRef.current?.focus();
  }, []);

  return (
    <div className="fitting-search">
      <div className="search-area">
        <div className="search-mode">
          <input
            type="text"
            placeholder="상호명 검색"
            ref={inputRef}
            value={query}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            onKeyDown={({ key, isComposing }) => {
              if (isComposing) {
                return;
              }

              if (key !== "Enter") {
                return;
              }

              dispatch(searchFittings(query));
            }}
          />
          {!!query && (
            <button className="fitting-clear" onClick={handleClickClear} />
          )}
        </div>
        <button className="fitting-close" onClick={handleClickClose} />
      </div>
      <div className="search-list">
        {searchList.map((item) => {
          return (
            <div className="search-item" onClick={handleClickDetail(item.id)}>
              <div className="search-thumb">
                <img src={item.imageUrl} />
              </div>
              <div className="search-desc">
                <div className="search-title">{item.name}</div>
                <div className="search-line">{item.description} </div>
                <div className="search-address">{item.address}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FittingSearch;
