import React, { useEffect, useState } from "react";
import Navigator from "../../components/navigator";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNewsBanner } from "../../redux/service/saga";
import { homeSelector } from "../../redux/service/selector";
import basic from "../../img/basic.png";
import splash from "../../img/splash.png";
import { setHomeDimmed } from "../../redux/service/slice";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const home = useSelector(homeSelector);
  const [showSplash, setShowSplash] = useState(true);
  const handleClickHelp = () => {
    navigate("/help");
  };

  const handleClickBanner = () => {
    if (home?.newsBanner?.linkUrl) {
      window.location.href = home?.newsBanner?.linkUrl;
    }
  };

  useEffect(() => {
    dispatch(getNewsBanner());
  }, [dispatch]);

  useEffect(() => {
    if (!home.dimmed) return;

    setTimeout(() => {
      setShowSplash(false);
    }, 1000);
  }, [home.dimmed]);

  return (
    <>
      {home.dimmed && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
            background: "#26b345",
            opacity: showSplash ? 1 : 0,
            transition: "opacity 0.2s ease",
          }}
          onTransitionEnd={() => dispatch(setHomeDimmed(false))}
        >
          <img
            src={splash}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            alt=""
          />
        </div>
      )}
      <div className="home">
        <div
          style={{
            position: "fixed",
            top: 0,
            color: "white",
            width: "100%",
            height: 45,
            background: home?.newsBanner?.colorCode,
          }}
        >
          <h1 className="title">myBirdie</h1>
          <button
            className="help-button"
            onClick={handleClickHelp}
            style={{ position: "fixed" }}
          />
        </div>
        <div
          className="logo-area"
          style={{
            textAlign: "center",
            background: home?.newsBanner?.colorCode,
          }}
        >
          <img
            src={home?.newsBanner?.mainUrl}
            style={{ maxWidth: 360, maxHeight: 462 }}
            alt=""
          />
        </div>
        <div className="logo-area" style={{ textAlign: "center" }}>
          <img
            src={home?.newsBanner?.bannerUrl}
            alt=""
            onClick={handleClickBanner}
          />
        </div>
        {/* <div className="news"></div> */}
      </div>
      <Navigator index={0} />
    </>
  );
};

export default Home;
