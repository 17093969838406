import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  home: {
    newsBanner: {
      bannerUrl: "",
      link: "",
      mainUrl: "",
      colorCode: "",
    },
    dimmed: true,
  },
  fitting: {
    cities: [],
    fittingShop: {},
    openedCities: ["서울"],
    citiesScroll: {},
  },
  fittingDetail: {},
  fittingSearch: {
    searchList: [],
  },
  board: {
    notice: "",
    posts: [],
  },
};

const serviceState = createSlice({
  name: "service",
  initialState,
  reducers: {
    setHomeDimmed: (state, { payload }) => {
      state.home.dimmed = payload;
    },
    addFittingShop: (state, { payload }) => {
      state.fitting.fittingShop = {
        ...state.fitting.fittingShop,
        ...payload,
      };
    },
    setFittingCities: (state, { payload }) => {
      state.fitting.cities = payload;
    },
    setOpenedCities: (state, { payload }) => {
      state.fitting.openedCities = payload;
    },
    setFittingDetail: (state, { payload }) => {
      state.fittingDetail = payload;
    },
    setBoardNotice: (state, { payload }) => {
      state.board.notice = payload;
    },
    setPostsNotice: (state, { payload }) => {
      state.board.posts = payload;
    },
    setNewsBanner: (state, { payload }) => {
      state.home.newsBanner = payload;
    },
    setFittingSearchList: (state, { payload }) => {
      state.fittingSearch.searchList = payload;
    },
    setFittingCitiesScroll: (state, { payload }) => {
      state.fitting.citiesScroll = {
        ...state.fitting.citiesScroll,
        ...payload,
      };
    },
  },
});

export const {
  setHomeDimmed,
  setFittingDetail,
  setBoardNotice,
  setPostsNotice,
  setNewsBanner,
  addFittingShop,
  setFittingCities,
  setFittingSearchList,
  setOpenedCities,
  setFittingCitiesScroll,
} = serviceState.actions;
export const serviceReducer = serviceState.reducer;
