import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const HelpDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const handleClickNav = () => {
    navigate(-1);
  };

  if (id === "1") {
    return (
      <>
        <div className="help-detail">
          <div className="sub-header scrolled">
            <button className="back" onClick={handleClickNav} />
            <div className="detail-title">서비스 이용약관</div>
          </div>
          <div className="content-area">
          제1조(목적)<br/>
&nbsp; &nbsp;• 마이버디 서비스(이하 ‘서비스’)를 이용해주심에 감사합니다.<br/>
&nbsp; &nbsp;• 본 약관은 홀인랩스(이하 ‘회사)가 제공하는 서비스 이용약관에 동의한 이용자(이하 ‘회원')
에 대한 회사와 회원의 권리∙의무 및 책임 사항에 대해 규정함을 목적으로 합니다.<br/><br/>
제2조(이용약관의 효력 및 변경)<br/>
&nbsp; &nbsp;• 이용자는 본 서비스에 접근하여 사용하는 경우, 본 약관의 내용을 충분히 이해하였으며
모든 내용에 동의한 것으로 간주합니다.<br/>
&nbsp; &nbsp;• 회사는 위치정보의 보호 및 이용 등에 관한 법률, 컨텐츠산업진흥법, 소비자기본법, 약관
의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습
니다.<br/>
&nbsp; &nbsp;• 회사가 약관을 개정할 경우에는 기존약관과 개정약관 및 적용일자, 적용사유를 명시하여
현행약관과 함께 그 적용일 이전부터 적용일 이후 상당한 기간동안 홈페이지 게시 등으
로 개정 사실을 고지합니다.<br/>
&nbsp; &nbsp;• 회사가 전항에 따라 회원에게 공지 또는 고지로부터 개정약관 시행일 7일 후까지 거부의
사를 표시하지 아니하면 이용약관에 승인한 것으로 간주하고, 회원이 개정약관에 동의하
지 않을 경우 회원은 이용계약을 해지할 수 있습니다.<br/><br/>
제3조(서비스의 내용)<br/>
&nbsp; &nbsp;• 서비스 명 : 마이버디<br/>
&nbsp; &nbsp;• 서비스 제공사항<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 골프피팅매장 정보 제공 : 전국 골프피팅매장에 대해 정보를 위치 및 연락처 등 정
보를 제공합니다.<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 커뮤니티 채널 제공 : 골프에 관한 모든 정보 공유가 되도록 오픈챗을 제공합니다.<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 골프 뉴스 제공 : 각종 골프 소식(대회, 신제품)에 대해 신속한 정보를 제공합니다.<br/><br/>
제4조(서비스 이용요금)<br/>
&nbsp; &nbsp;• 회사가 제공하는 서비스는 무료입니다.<br/>
&nbsp; &nbsp;• 단, 추후 서비스가 추가됨에 따라 일부 유료로 전환될 수 있습니다.<br/><br/>
제5조(서비스내용변경 통지 등)<br/>
&nbsp; &nbsp;• 회사가 서비스 내용을 변경하거나 종료하는 경우 회사는 서비스의 공자사항 등을 통하여
고지 또는 통지할 수 있습니다.<br/><br/>
제6조(서비스이용의 제한 및 중지)<br/>
&nbsp; &nbsp;• 회사는 아래와 같은 사유가 발생한 경우에는 회원의 서비스 이용을 제한하거나 중지시킬
수 있습니다.<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 회원이 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 앱의 이용약관 또는 이용자 정책을 위반하는 경우<br/>
서비스용 설비에 대해 점검 또는 보수, 공사로 인하여 부득이한 경우<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 국가비상사태, 재난재해 또는 서비스 장애 등으로 서비스 이용에 지장이 있을 경우<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정
될 경우<br/>
&nbsp; &nbsp;• 회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제
한기간 등을 회원 또는 이용자에게 알려야합니다.<br/><br/>
제7조(정보제공 및 광고의 게제)<br/>
&nbsp; &nbsp;• 회사는 회원 또는 이용자가 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사
항 등의 방법으로 회원에게 제공할 수 있습니다.<br/>
&nbsp; &nbsp;• 회사는 서비스의 운영과 관련하여 서비스 화면, 홈페이지, 어플리케이션, 푸시메시지 등
에 광고를 게재할 수 있습니다. 광고를 수신한 회원은 언제든 수신거절에 대해 회사에게
요청할 수 있습니다.<br/><br/>
제8조(게시물의 저작권)<br/>
&nbsp; &nbsp;• 회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다.<br/>
&nbsp; &nbsp;• 회원이 서비스 내에 게시하는 게시물은 검색결과 내지 서비스 및 관련 프로모션 등에 노
출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게
시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며 회원은 언제든지 고객센터
를 통해 해당 게시물의 대해 삭제, 검색결과 제외, 비공개 등의 조치를 요구할 수 있습니
다.<br/>
&nbsp; &nbsp;• 서비스 내 모든 게시물 및 정보(매장 위치, 연락처 등)의 스크랩핑(크롤링) 및 정보 수집
을 금합니다.<br/><br/>
제9조(게시물의 관리)<br/>
&nbsp; &nbsp;• 회원의 게시물이 ‘정보통신망법' 및 ‘저작권법' 등 관련법에 위반되는 내용을 포함하는 경
우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할
수 있으며 회사는 관련법에 따라 조치를 취하여야 합니다.<br/>
&nbsp; &nbsp;• 회사는 서비스 이용약관 위반 여부 확인 및 분쟁해결의 목적으로 채팅 혹은 게시물에 대
하여 기록을 저장하고 관리하며 모니터링 합니다. 특히 공공질서 및 저속한 표현, 타인의
개인정보를 포함하는 표현, 회사 또는 타인의 명예를 손상시키거나 불이익을 주는 표현
은 금지됩니다.<br/>
&nbsp; &nbsp;• 회사는 전항에 따른 권리자의 요청이 없는 경우에도 권리침해가 인정될 만한 사유가 있
거나 기타 회사 정책 및 관련법에 위반되는 경우(사회통념상 올바르지 않거나 불쾌감을
주는 경우 등)에는 관련법에 따라 해당 게시물에 대해 임시조치 및 삭제 등을 취할 수
있습니다.<br/><br/>
제10조(권리의 귀속)<br/>
&nbsp; &nbsp;• 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다. 단, 회원의 게시물 및 제휴계
약에 따라 제공된 저작물 등은 제외합니다.<br/>
&nbsp; &nbsp;• 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 컨텐츠 등을 이용할
수 있는 이용권만 부여하며 회원은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없
습니다.<br/><br/>
제11조(개인정보의 보호 및 사용)<br/>
&nbsp; &nbsp;• 회사는 회원의 개인정보를 보호하기 위하여 정보통신망법 및 개인정보보호법 등 관련 법
령에서 정하는 바를 준수합니다.<br/>
&nbsp; &nbsp;• 회사는 회원의 개인정보를 보호하기 위하여 개인정보처리방침을 제정, 서비스 화면에 게
시합니다. 개인정보처리방침의 구체적 내용은 별도 공지된 개인정보처리방침 화면을 통
하여 볼 수 있습니다.<br/>
&nbsp; &nbsp;• 회사의 공식 사이트 이외 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않
습니다. 링크된 사이트 및 서비스를 제공하는 제3자의 개인정보 취급과 관련하여는 해당
사이트 및 제3자의 개인정보처리방침을 확일할 책임이 회원에게 있으며, 회사는 이에 대
하여 책임을 부담하지 않습니다.<br/>
&nbsp; &nbsp;• 회사는 다음과 같은 경우에 법이 허용하는 범위 내에서 회원의 개인정보를 제3자에게 제
공할 수 있습니다.<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 수사기관이나 기타 정부기관으로부터 정보제공을 요청 받은 경우<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 회원이 법령 또는 약관의 위반을 포함하여 부정행위 확인 등의 정보보호 업무를 위
해 필요한 경우<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 서비스 이용 중 별도 동의를 받은 경우<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 기타 법률에 의해 요구되는 경우<br/><br/>
제12조(책임제한)<br/>
&nbsp; &nbsp;• 회사는 회원과 제휴매장 간의 정보를 제공하는 플랫폼 서비스만을 제공할 뿐 재화를 판
매하는 당사자가 아니며, 재화에 대한 책임은 제휴매장에게 있습니다.<br/>
&nbsp; &nbsp;• 회사는 제휴매장이 게재한 정보, 자료, 정확성 등 내용에 관해서는 책임을 지지 않습니다.<br/>
&nbsp; &nbsp;• 회사는 아래 내용의 경우로 서비스를 제공할 수 없는 경우, 이로 인하여 회원에게 발생
한 손해에 대해서는 책임을 부담하지 않습니다.<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스
방해가 있는 경우<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 회원의 귀책사유로 서비스 이용에 장애가 있는 경우<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 서비스에 대한 접속 및 서비스의 이용과정에서 발생하는 개인적인 손해<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터 발생
하는 손해<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 서버에 대한 전송 또는 서버로부터의 전송에 대한 제3자의 모든 불법적인 방해 또는
중단행위로 발생하는 손해<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 제3자가 서비스를 이용하여 불법적으로 전송∙유포하거나 전송∙유포되도록 한 모든
멀웨워, 스파이웨어 및 기타 악성 프로그램으로 인한 손해<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 전송된 데이터의 오류 생략, 누락, 파괴 등으로 발생되는 손해<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 회원간 서비스 이용과정에서 발생하는 명예훼손 및 기타 불법행위로 인한 각종 민∙
형사상 책임<br/>
&nbsp; &nbsp;• 회사의 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다.
다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리 일정을 미리 통보합니다.<br/>
&nbsp; &nbsp;• 회사와 회원간에 발생한 분쟁과 관련하여 이용자의 피해구제 신청이 있는 경우, 공정거
래위원회 또는 서울ᅡ부지방법원의 조정에 따를 수 있습니다.<br/><br/>
제13조(규정의 준용)<br/>
&nbsp; &nbsp;• 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.<br/>
&nbsp; &nbsp;• 본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항
에 대하여는 관계법령 또는 상관례에 따릅니다.<br/><br/>
제14조(회사의 연락처)<br/>
&nbsp; &nbsp;• 회사의 상호 및 주소 등은 다음과 같습니다.<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 상호 : 홀인랩스<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 대표자 : James Choi<br/>
&nbsp; &nbsp;&nbsp; &nbsp;o 연락처 : team@hole1n.com<br/>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="help-detail">
          <div className="sub-header scrolled">
            <button className="back" onClick={handleClickNav} />
            <div className="detail-title">개인정보 처리방침</div>
          </div>
          <div className="content-area">
            홀인랩스 (이하 “회사")는 「개인정보 보호법」 제30조에 따라
            정보주체의 개인정보를 보호하고 이 와 관련한 고충을 신속하고 원활하게
            처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리 방침을
            수립·공개합니다.
            <br />
            <br />
            ○ 이 개인정보처리방침은 2023년 1월 1부터 적용됩니다.
            <br />
            <br />
            제1조(개인정보의 처리 목적) 회사는 다음의 목적을 위하여 개인정보를
            처리합니다. 처리하고 있 는 개인정보는 다음의 목적 이외의 용도로는
            이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」
            제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
            1. 마이버디 서비스(이하 “서비스") 재화 또는 서비스 제공과 콘텐츠
            제공을 목적으로 개인 정보를 처리합니다.
            <br />
            <br />
            제2조(개인정보의 처리 및 보유 기간)
            <br />
            ① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
            개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서
            개인정보를 처리·보유합니다.
            <br />
            ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
            <br />
            &nbsp;&nbsp;1. 작성자 성명
            <br />
            &nbsp;&nbsp;2. 작성 내용
            <br />
            와 관련한 개인정보는 수집.이용에 관한 동의일로부터까지 위 이용목적을
            위하여 보유.이용됩니다.
            <br />
            보유근거: 관련 법령에 의거
            <br />
            관련법령: 1)소비자의 불만 또는 분쟁처리에 관한 기록: 3년
            <br />
            2) 표시/광고에 관한 기록: 6개월
            <br />
            <br />
            제3조(처리하는 개인정보의 항목)
            <br />
            ① 회사는 다음의 개인정보 항목을 처리하고 있습니다.
            <br />
            &nbsp;&nbsp; 1. &lt; 재화 또는 서비스 제공 &gt;
            <br />
            필수항목: 이름, 서비스 이용 기록, 접속 로그, 쿠키
            <br />
            <br />
            제4조(개인정보의 제3자 제공에 관한 사항)
            <br />
            ① 회사는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위
            내에서만 처리하며, 정보주 체의 동의, 법률의 특별한 규정 등
            「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개 인정보를
            제3자에게 제공합니다.
            <br />
            <br />
            제5조(개인정보의 파기절차 및 파기방법)
            <br />
            ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
            불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
            <br />
            ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
            달성되었음에도 불구하 고 다른 법령에 따라 개인정보를 계속 보존하여야
            하는 경우에는, 해당 개인정보를 별도의 데이 터베이스(DB)로 옮기거나
            보관장소를 달리하여 보존합니다.
            <br />
            &nbsp;&nbsp; 1. 법령 근거: 회사 내부 규정
            <br />
            &nbsp;&nbsp; 2. 보존하는 개인정보 항목: 작성자명, 작성내용
            <br />
            ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
            <br />
            &nbsp;&nbsp;1. 파기절차
            <br />
            회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
            보호책임자의 승인을 받 아 개인정보를 파기합니다.
            <br />
            &nbsp;&nbsp; 2. 파기방법
            <br />
            전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
            사용합니다.
            <br />
            종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
            파기합니다.
            <br />
            <br />
            제6조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)
            <br />
            ① 정보주체는 홀인랩스에 대해 언제든지 개인정보
            열람·정정·삭제·처리정지 요구 등의 권리를 행 사할 수 있습니다.
            <br />
            ② 제1항에 따른 권리 행사는홀인랩스에 대해 「개인정보 보호법」 시행령
            제41조제1항에 따라 서 면, 전자우편, 모사전송(FAX) 등을 통하여 하실
            수 있으며 홀인랩스은(는) 이에 대해 지체 없이 조 치하겠습니다.
            <br />
            ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자
            등 대리인을 통하여 하 실 수 있습니다.이 경우 “개인정보 처리 방법에
            관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야
            합니다.
            <br />
            ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항,
            제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
            <br />
            ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
            대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
            <br />
            ⑥ 홀인랩스은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
            처리정지의 요구 시 열 람 등 요구를 한 자가 본인이거나 정당한
            대리인인지를 확인합니다.
            <br />
            <br />
            제7조(개인정보의 안전성 확보조치에 관한 사항)
            <br />
            회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
            있습니다.
            <br />
            &nbsp;&nbsp; 1. 정기적인 자체 감사 실시
            <br />
            개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체
            감사를 실시하고 있습니다.
            <br />
            &nbsp;&nbsp; 2. 개인정보 취급 직원의 최소화 및 교육
            <br />
            개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여
            개인정보를 관리하는 대책 을 시행하고 있습니다.
            <br />
            &nbsp;&nbsp; 3. 내부관리계획의 수립 및 시행
            <br />
            개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
            있습니다.
            <br />
            &nbsp;&nbsp; 4. 해킹 등에 대비한 기술적 대책
            <br />
            서비스는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을
            막기 위하여 보안프로그 램을 설치하고 주기적인 갱신·점검을 하며
            외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로
            감시 및 차단하고 있습니다.
            <br />
            &nbsp;&nbsp; 5. 개인정보의 암호화
            <br />
            이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어,
            본인만이 알 수 있으며 중 요한 데이터는 파일 및 전송 데이터를
            암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을
            사용하고 있습니다.
            <br />
            &nbsp;&nbsp; 6. 접속기록의 보관 및 위변조 방지
            <br />
            개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고
            있으며 다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나,
            고유식별정보 또는 민감정보를 처리하는 경우에는 2 년이상 보관,
            관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록
            보안기능을 사용하고 있습니다.
            <br />
            &nbsp;&nbsp; 7. 개인정보에 대한 접근 제한
            <br />
            개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
            부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
            조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무 단
            접근을 통제하고 있습니다.
            <br />
            <br />
            제8조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에
            관한 사항)
            <br />
            ① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를
            저장하고 수시로 불러오 는 ‘쿠키(cookie)’를 사용합니다.
            <br />
            ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
            브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
            하드디스크에 저장되기도 합니다.
            <br />
            가. 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한
            방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여
            이용자에게 최적화된 정보 제공을 위해 사용됩니다.
            <br />
            나. 쿠키의 설치&nbsp; &nbsp;•운영 및 거부: 웹브라우저 상단의 도구&gt;인터넷
            옵션&gt;개인정보 메뉴의 옵션 설정 을 통해 쿠키 저장을 거부할 수
            있습니다.
            <br />
            다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
            있습니다.
            <br />
            <br />
            제9조 (개인정보 보호책임자에 관한 사항)
            <br />
            ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
            처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
            같이 개인정보 보호책임자를 지정하고 있습니다.
            <br />
            ▶ 개인정보 보호책임자
            <br />
            성명: James Choi
            <br />
            직책: CEO
            <br />
            연락처: privacy@hole1n.com
            <br />
            ② 정보주체께서는 홀인랩스 의 서비스(또는 사업)을 이용하시면서 발생한
            모든 개인정보 보호 관 련 문의, 불만처리, 피해구제 등에 관한 사항을
            개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 홀인랩스
            은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
            <br />
            <br />
            제10조(개인정보의 열람청구를 접수·처리하는 부서)
            <br />
            정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를
            아래의 부서에 할 수 있습 니다.
            <br />
            회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록
            노력하겠습니다.
            <br />
            ▶ 개인정보 열람청구 접수·처리 부서
            <br />
            부서명: Team Mybirdie
            <br />
            연락처: team@hole1n.com
            <br />
            <br />
            제11조(정보주체의 권익침해에 대한 구제방법)
            <br />
            정보주체는 개인정보침해로 인한 구제를 받기 위하여
            개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
            분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보
            침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
            <br />
            &nbsp;&nbsp; 1. 개인정보분쟁조정위원회: (국번없이) 1833-6972
            (www.kopico.go.kr)
            <br />
            &nbsp;&nbsp; 2. 개인정보침해신고센터: (국번없이) 118
            (privacy.kisa.or.kr)
            <br />
            &nbsp;&nbsp; 3. 대검찰청: (국번없이) 1301 (www.spo.go.kr)
            <br />
            &nbsp;&nbsp; 4. 경찰청: (국번없이) 182 (ecrm.cyber.go.kr)
            <br />
            「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의
            정정·삭제), 제37조(개인정보의 처리 정지 등)의 규정에 의한 요구에 대
            하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의
            침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수
            있습니다.
            <br />
            ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr)
            홈페이지를 참고하시기 바랍니다.
            <br />
            <br />
            제12조(개인정보 처리방침 변경)
            <br />① 이 개인정보처리방침은 2023년 1월 1부터 적용됩니다.
            <br />
            <br />
          </div>
        </div>
      </>
    );
  }
};

export default HelpDetail;
