import React from "react";
import BasicCard from "./item";

import { useSelector } from "react-redux";
import { adminFittingSelector } from "../../../redux/admin/selector";
const AdminFittingList = ({ city }) => {
  const { fittingShop } = useSelector(adminFittingSelector);

  return (
    <>
      <h3>{city}</h3>
      <div style={{ display: "inline-block", margin: 10 }}>
        {fittingShop[city]?.map((shop) => {
          return <BasicCard shop={shop} city={city} />;
        })}
      </div>
      <br />
    </>
  );
};

export default AdminFittingList;
