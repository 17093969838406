import "./App.css";
import Home from "./page/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Fitting from "./page/fitting";
import FittingSearch from "./page/fitting/fitting-search";
import FittingDetail from "./page/fitting/fitting-detail";
import Guide from "./page/guide";
import RuleDetail from "./page/guide/rule-detail";
import Rule from "./page/guide/rule";
import Term from "./page/guide/term";
import Board from "./page/board";
import BoardWrite from "./page/board/write";
import Admin from "./page/admin";
import Help from "./page/help";
import HelpDetail from "./page/help/help-detail";

function App() {
  return (
    <Router>
      <Routes path="/" element={<>dd</>}>
        <Route index element={<Home />} />
        <Route path="/fitting" element={<Fitting />} />
        <Route path="/fitting-search" element={<FittingSearch />} />
        <Route path="/fitting-detail/:id" element={<FittingDetail />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/guide-rule" element={<Rule />} />
        <Route path="/guide-rule-detail" element={<RuleDetail />} />
        <Route path="/guide-term" element={<Term />} />
        <Route path="/board" element={<Board />} />
        <Route path="/board-write" element={<BoardWrite />} />
        <Route path="/help" element={<Help />} />
        <Route path="/help-detail/:id" element={<HelpDetail />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </Router>
  );
}

export default App;
