import { createAction } from "@reduxjs/toolkit";
import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  setAdminNotice,
  setAdminPosts,
  setAdminNewsBanner,
  appendAdminFittingShop,
  setAdminFittingCities,
  setFittingShopLayer,
  setAdminLogoImage,
  setAdminBackgroundColor,
} from "./slice";
import axios from "axios";
import { errorHandler } from "../../util/errorUtil";

export const getAdminPosts = createAction("admin/board/getPosts");
export const addAdminNotice = createAction("admin/board/addNotice");
export const getAdminNotice = createAction("admin/board/getNotice");
export const deleteAdminPost = createAction("admin/board/deletePost");
export const getAdminNewsBanner = createAction("admin/board/getNewsBanner");
export const addAdminNewsBanner = createAction("admin/board/addNewsBanner");
export const getAdminLogo = createAction("admin/board/getAdminLogo");
export const addAdminLogo = createAction("admin/board/addAdminLogo");
export const getAdminBackground = createAction(
  "admin/board/getAdminBackground"
);
export const addAdminBackground = createAction(
  "admin/board/addAdminBackground"
);
export const getAdminFitting = createAction("admin/board/getAdminFitting");
export const showAdminFittingDetail = createAction(
  "admin/board/showAdminFittingDetail"
);
export const addAdminFitting = createAction("admin/board/addAdminFitting");
export const deleteAdminFitting = createAction(
  "admin/board/deleteAdminFitting"
);

function* deletePostSaga({ payload }) {
  try {
    yield call(axios.delete, `/v1/posts/${payload}`);
    alert("삭제가 완료되었습니다.");
    yield put(getAdminPosts());
  } catch (error) {
    errorHandler(error);
  }
}

function* getPostsSaga() {
  try {
    const posts = yield call(axios.get, `/v1/posts`);

    yield put(setAdminPosts(posts.data.posts));
  } catch (error) {}
}

function* addNoticeSaga({ payload }) {
  try {
    yield call(axios.post, `/v1/notices`, {
      content: payload.content,
    });
    yield put(getAdminNotice());
    alert("저장이 완료되었습니다.");
  } catch (error) {
    errorHandler(error);
  }
}

function* getNoticeSaga() {
  try {
    const notice = yield call(axios.get, `/v1/notices`);

    yield put(setAdminNotice(notice.data.content));
  } catch (error) {}
}

function* getAdminNewsBannerSaga({ payload }) {
  try {
    const banner = yield call(axios.get, `/v1/news/banner`);

    yield put(
      setAdminNewsBanner({
        imageUrl: banner.data.bannerUrl,
        linkUrl: banner.data.linkUrl,
      })
    );
  } catch (e) {}
}

function* addAdminNewsBannerSaga({ payload }) {
  try {
    const formData = new FormData();
    if (payload.image) {
      formData.append("bannerImageFile", payload.image);
    }
    if (payload.linkUrl) {
      formData.append("linkUrl", payload.linkUrl);
    }

    const banner = yield call(axios, {
      method: "post",
      url: "/v1/news/banner",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    yield put(setAdminNewsBanner({ imageUrl: banner.data.bannerUrl }));
    alert("저장이 완료되었습니다.");
  } catch (e) {}
}

function* getFittingSaga({ payload }) {
  try {
    const cities = yield call(axios.get, `/v1/fittingShops/cities`);
    const cityList = cities.data.cities;
    yield put(setAdminFittingCities(cityList));
    const result = yield all(
      cityList.map((city) =>
        call(axios.get, `/v1/fittingShops?city=${encodeURIComponent(city)}`)
      )
    );

    yield all(
      result.map((fitting, index) =>
        put(
          appendAdminFittingShop({
            [cityList[index]]: fitting.data.fittingShops,
          })
        )
      )
    );
  } catch (error) {}
}

function* showAdminFittingDetailSaga({ payload }) {
  try {
    const detail = yield call(axios.get, `/v1/fittingShops/${payload.id}`);

    yield put(
      setFittingShopLayer({
        show: true,
        isNew: false,
        field: { city: payload.city, ...detail.data },
      })
    );
  } catch (e) {}
}

function* addFittingSaga({ payload }) {
  try {
    const formData = new FormData();
    payload.imageFile && formData.append("imageFile", payload.imageFile);
    formData.append("city", payload.city);
    formData.append("name", payload.name);
    formData.append("description", payload.description);
    formData.append("address", payload.address);
    formData.append("phoneNumber", payload.phoneNumber);
    formData.append("content", payload.content);

    yield call(axios, {
      method: payload.isNew ? "post" : "put",
      url: payload.isNew
        ? "/v1/fittingShops"
        : `/v1/fittingShops/${payload.id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    yield put(getAdminFitting());
    payload.callback();
    alert("저장이 완료되었습니다.");
  } catch (error) {
    errorHandler(error);
  }
}

function* deleteFittingShopSaga({ payload }) {
  try {
    if (!window.confirm("해당 피팅샵을 삭제하시겠습니까?")) {
      return;
    }
    yield call(axios.delete, `/v1/fittingShops/${payload}`, {
      content: payload.content,
    });
    yield put(getAdminFitting());
  } catch (error) {
    errorHandler(error);
  }
}

function* getAdminLogoSaga() {
  try {
    const logo = yield call(axios.get, `/v1/news/banner`);

    yield put(setAdminLogoImage(logo.data.mainUrl));
  } catch (e) {}
}
function* addAdminLogoSaga({ payload }) {
  try {
    const formData = new FormData();
    if (payload.image) {
      formData.append("mainImageFile", payload.image);
    }

    const logo = yield call(axios, {
      method: "post",
      url: "/v1/news/mainImage",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    yield put(getAdminLogo({ imageUrl: logo.data.mainUrl }));
    alert("저장이 완료되었습니다.");
  } catch (error) {
    errorHandler(error);
  }
}
function* getAdminBackgroundSaga() {
  try {
    const banner = yield call(axios.get, `/v1/news/banner`);

    yield put(setAdminBackgroundColor(banner.data.colorCode));
  } catch (e) {}
}
function* addAdminBackgroundSaga({ payload }) {
  try {
    yield call(
      axios.post,
      `/v1/news/backgroundColor?colorCode=${encodeURIComponent(payload.color)}`
    );
    yield put(getAdminBackground());
    alert("저장이 완료되었습니다.");
  } catch (error) {
    errorHandler(error);
  }
}
export function* adminSaga() {
  yield takeLatest(deleteAdminPost, deletePostSaga);
  yield takeLatest(getAdminPosts, getPostsSaga);
  yield takeLatest(addAdminNotice, addNoticeSaga);
  yield takeLatest(getAdminNotice, getNoticeSaga);
  yield takeLatest(getAdminNewsBanner, getAdminNewsBannerSaga);
  yield takeLatest(addAdminNewsBanner, addAdminNewsBannerSaga);
  yield takeLatest(getAdminLogo, getAdminLogoSaga);
  yield takeLatest(addAdminLogo, addAdminLogoSaga);
  yield takeLatest(getAdminBackground, getAdminBackgroundSaga);
  yield takeLatest(addAdminBackground, addAdminBackgroundSaga);
  yield takeLatest(getAdminFitting, getFittingSaga);
  yield takeLatest(showAdminFittingDetail, showAdminFittingDetailSaga);
  yield takeLatest(addAdminFitting, addFittingSaga);
  yield takeLatest(deleteAdminFitting, deleteFittingShopSaga);
}
