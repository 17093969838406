import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Rule = () => {
    const navigate = useNavigate();
    const handleClickNav = () => {
        navigate(-1);
    }

    const handleClickDetail = () => {
        navigate("/guide-rule-detail");
    }

    return (
        <>
            <div className="rule">
                <div className="sub-header scrolled">
                    <button className="back" onClick={handleClickNav} />
                    <div className="detail-title">R&A 골프 규칙</div>
                </div>
                <div className="item-list">
                    <div className="item" onClick={handleClickDetail}>
                        <span>1. The Game, Player Conduct and the Rules</span>
                        <button className="btn" />
                    </div>
                    <div className="item">
                        <span>1. The Game,ules</span>
                        <button className="btn" />
                    </div>
                    <div className="item">
                        <span>1. The Game,ules</span>
                        <button className="btn" />
                    </div>
                    <div className="item">
                        <span>1. The Game,ules</span>
                        <button className="btn" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Rule;
