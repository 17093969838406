import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { setFittingShopLayer } from "../../../redux/admin/slice";

export function AddFitting() {
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    dispatch(
      setFittingShopLayer({
        show: true,
        isNew: true,
        field: {},
      })
    );
  };

  return (
    <>
      <Button
        variant="contained"
        component="label"
        onClick={handleClickOpen}
        sx={{ mt: 1 }}
      >
        피팅샵 추가
      </Button>
    </>
  );
}
