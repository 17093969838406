import React, { useEffect } from "react";
import AdminFittingList from "./list";
import { useDispatch } from "react-redux";
import { getAdminFitting } from "../../../redux/admin/saga";
import { AddFitting } from "./AddFitting";
import { useSelector } from "react-redux";
import { adminFittingSelector } from "../../../redux/admin/selector";
import { FittingLayer } from "./fittingLayer";

const AdminFitting = () => {
  const dispatch = useDispatch();
  const { cities, layer } = useSelector(adminFittingSelector);

  useEffect(() => {
    dispatch(getAdminFitting());
  }, [dispatch]);

  return (
    <div style={{ marginLeft: 20 }}>
      <AddFitting />
      {cities.map((city) => (
        <AdminFittingList city={city} />
      ))}
      <br />
      {layer.show && <FittingLayer />}
    </div>
  );
};

export default AdminFitting;
