import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addPost } from "../../../redux/service/saga";

const BoardWrite = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [content, setContent] = useState("");
  const handleClickNav = () => {
    navigate(-1);
  };

  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };

  const handleChangeContent = (event) => {
    setContent(event.target.value);
  };

  const handleClickComplete = () => {
    if (!enableSubmit) return;
    setEnableSubmit(false);
    dispatch(
      addPost({
        user,
        content,
        callback: () => {
          navigate(-1);
        },
        fallback: () => {
          setEnableSubmit(true);
        }
      })
    );
  };

  return (
    <div className="board-write">
      <div className={`sub-header scrolled`}>
        <button className="back" onClick={handleClickNav} />
        <div className="detail-title">글 작성</div>
        <button className="complete" onClick={handleClickComplete}>
          완료
        </button>
      </div>
      <div className="write-area">
        <div className="write-title">
          <input
            placeholder="작성자"
            onChange={handleChangeUser}
          />
        </div>
        <div className="write-content">
          <textarea
            placeholder="글을 작성해보세요"
            onChange={handleChangeContent}
          />
        </div>
      </div>
    </div>
  );
};

export default BoardWrite;
