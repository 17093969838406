import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { deleteAdminFitting } from "../../../redux/admin/saga";
import { useDispatch } from "react-redux";
import { showAdminFittingDetail } from "../../../redux/admin/saga";

export default function BasicCard({ shop, city }) {
  const { imageUrl, name, description, address, id } = shop;
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    dispatch(showAdminFittingDetail({ id, city }));
  };

  const handleClickDelete = (e) => {
    e.stopPropagation();
    dispatch(deleteAdminFitting(id));
  };

  return (
    <>
      <Card
        sx={{ width: "220px", float: "left", margin: "5px" }}
        onClick={handleClickOpen}
      >
        <CardContent>
          <img src={imageUrl} style={{ width: 189, height: 100 }} />
          <Typography
            sx={{
              mb: 0,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            color="text.secondary"
            fontSize={15}
          >
            상호명: {name || ""}
          </Typography>
          <Typography
            sx={{
              mb: 0,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            color="text.secondary"
            fontSize={15}
          >
            설명: {description || ""}
          </Typography>
          <Typography
            sx={{
              mb: 0,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            color="text.secondary"
            fontSize={15}
          >
            주소: {address || ""}
          </Typography>
          <Button
            fullWidth
            color="error"
            variant="outlined"
            onClick={handleClickDelete}
          >
            삭제
          </Button>
        </CardContent>
      </Card>
    </>
  );
}
