import React from "react";

const Layer = ({ clickLayer }) => {
    return (
        <>
            <div className="layer" onClick={clickLayer}>
                <div className="layer_inner">
                    <div className="layer_area">
                        <div className="bar" />
                        <div className="title">
                            파(par)
                        </div>
                        <div className="desc">
                            각 홀의 표준 타수
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Layer;
