import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getDate } from "../../../util/dateUtil";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { deleteAdminPost } from "../../../redux/admin/saga";

export function Post({ content, writer, createdTime, id }) {
  const dispatch = useDispatch();
  const handleClickDelete = () => {
    if (window.confirm("게시글을 삭제하시겠습니까?")) {
      dispatch(deleteAdminPost(id));
    }
  };
  return (
    <Card sx={{ margin: 1 }} fullWidth>
      <CardContent>
        <Button
          variant="contained"
          color="error"
          component="label"
          sx={{ margin: "20px 20px 20px 0" }}
          onClick={handleClickDelete}
        >
          삭제
        </Button>
        <Typography color="text.secondary">id: {id}</Typography>
        <Typography color="text.secondary">작성자: {writer}</Typography>
        <Typography color="text.secondary">
          작성시간: {getDate(createdTime)}
        </Typography>
        <Typography color="text.secondary">내용: {content}</Typography>
      </CardContent>
    </Card>
  );
}
