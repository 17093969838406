import React, { useState } from "react";
import Navigator from "../../components/navigator";
import Rule from "../../img/rule.png"
import Term from "../../img/term.png"
import Layer from "./layer";
import { useNavigate } from "react-router-dom";

const Guide = () => {
    const navigate = useNavigate();
    const [showLayer, setShowLayer] = useState(false);
    const handleClickLayer = () => {
        setShowLayer(!showLayer);
    }

    const handleClickRule = () => {
        navigate("/guide-rule");
    }

    
    const handleClickTerm = () => {
        navigate("/guide-term");
    }
    return (
        <>
            <h1 className="title">길잡이</h1>
            <div className="guide">
                <div className="guide-box">
                    <div className="rule" onClick={handleClickRule} >
                        <div className="thumb">
                            <img src={Rule} />
                        </div>
                        <div className="text">
                            R&A 규칙
                        </div>
                    </div>
                    <div className="terms" onClick={handleClickTerm} >
                        <div className="thumb">
                            <img src={Term} />
                        </div>
                        <div className="text">
                            R&A 용어
                        </div></div>
                </div>
                <div className="dc">
                    <h2>골프 용어</h2>
                    <div className="dc-title">
                        ㄱ
                        <button className={"unfold"} />
                    </div>
                    <div className="dc-sub" onClick={handleClickLayer}>
                        단어
                    </div>
                    <div className="dc-sub">
                        단어
                    </div>
                    <div className="dc-title">
                        ㄱ
                        <button className={"unfold"} />
                    </div>
                    <div className="dc-sub" onClick={handleClickLayer}>
                        단어
                    </div>
                    <div className="dc-sub">
                        단어
                    </div>
                    <div className="dc-title">
                        ㄱ
                        <button className={"unfold"} />
                    </div>
                    <div className="dc-sub" onClick={handleClickLayer}>
                        단어
                    </div>
                    <div className="dc-sub">
                        단어
                    </div>
                    <div className="dc-title">
                        ㄱ
                        <button className={"unfold"} />
                    </div>
                    <div className="dc-sub" onClick={handleClickLayer}>
                        단어
                    </div>
                    <div className="dc-sub">
                        단어
                    </div>

                </div>

            </div>
            {showLayer && <Layer clickLayer={handleClickLayer} />}
            <Navigator index={2} />
        </>
    );
}

export default Guide;
