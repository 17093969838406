import React from "react";

const Alert = ({ onClose, onReport }) => {
  const handleClickCancel = () => {
    onClose();
  };
  return (
    <>
      <div className="alert" onClick={handleClickCancel}>
        <div className="layer_inner">
          <div className="layer_area">
            <div className="alert_title">선택해주세요.</div>
            <div className="alert_desc" onClick={onReport}>신고하기</div>
            <div className="alert_task">
              <button className="cancel" onClick={handleClickCancel}>
                취소
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Alert;
