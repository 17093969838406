import React, { useState } from "react";
import Alert from "../alert";
import { getDate } from "../../../util/dateUtil";

const BoardItem = (props) => {
  const [showAlert, setShowAlert] = useState(false);

  const handleClickMore = () => {
    setShowAlert(!showAlert);
  };

  const onReport = () => {
    window.location.href = "mailto:privacy@hole1n.com";
    setShowAlert(false);
  };

  return (
    <>
      <div className="board-item">
        <div className="board-title">
          <span className="user">{props.writer}</span>
          <span className="time">{getDate(props.createdTime)}</span>
          <button className="more" onClick={handleClickMore} />
        </div>
        <div className="board-content">
          {props.content.split("\n").map((txt, index) => {
            return (
              <React.Fragment key={`${txt}-${index}`}>
                {txt}
                <br />
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {showAlert && <Alert onClose={handleClickMore} onReport={onReport} />}
    </>
  );
};

export default BoardItem;
