import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { serviceReducer } from "./service/slice";
import { adminReducer } from "./admin/slice";
import { homeSaga } from "./service/saga";
import { adminSaga } from "./admin/saga";
import { all } from "redux-saga/effects";

const sagaMiddleware = createSagaMiddleware();

const createStore = () => {
  const store = configureStore({
    reducer: combineReducers({ serviceReducer, adminReducer }),
    middleware: [sagaMiddleware],
  });

  const rootSaga = function* () {
    yield all([homeSaga(), adminSaga()]);
  };

  sagaMiddleware.run(rootSaga);

  return store;
};

export default createStore;
