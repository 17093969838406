import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AdminNews from "./news";
import AdminFitting from "./fitting";
import AdminBoard from "./board";
import AdminLogo from "./logo";
import { Input } from "@mui/material";
import AdminBackground from "./background";

export default function FullWidthTabs() {
  const [value, setValue] = useState(0);
  const [secretMode, setSecretMode] = useState(true);
  const [password, setPassword] = useState("");

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleKeyDown = ({ key, isComposing }) => {
    if (isComposing) {
      return;
    }

    if (key !== "Enter") {
      return;
    }

    if (password === "james") {
      setSecretMode(false);
    }
  };

  if (secretMode) {
    return (
      <div>
        <Input
          onChange={handleChangePassword}
          onKeyDown={handleKeyDown}
          value={password}
        />
      </div>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="홈 로고" />
        <Tab label="홈 백그라운드 컬러" />
        <Tab label="홈 뉴스배너" />
        <Tab label="피팅" />
        <Tab label="그늘집" />
      </Tabs>
      {value === 0 && <AdminLogo />}
      {value === 1 && <AdminBackground />}
      {value === 2 && <AdminNews />}
      {value === 3 && <AdminFitting />}
      {value === 4 && <AdminBoard />}
    </Box>
  );
}
