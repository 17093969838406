import dayjs from "dayjs";

export const getDate = (time) => {
  const date = dayjs(time);
  const isToday = date.isToday();

  if (isToday) {
    return date.format("HH:mm");
  }
  return date.format("MM-DD HH:mm");
};
