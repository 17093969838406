import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFittingDetail } from "../../../redux/service/saga";
import { fittingDetailSelector } from "../../../redux/service/selector";
import { setFittingDetail } from "../../../redux/service/slice";

const FittingDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isScrolled, setIsScrolled] = useState(0);
  const fittingDetail = useSelector(fittingDetailSelector);

  const handleClickNav = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getFittingDetail(id));

    return () => {
      dispatch(setFittingDetail({}));
    };
  }, [dispatch, id]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(!!window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="fitting-detail">
      <div className={`sub-header ${isScrolled && "scrolled"}`}>
        <button className="back" onClick={handleClickNav} />
        <div className="detail-title">{fittingDetail?.name}</div>
      </div>
      <div className="detail-thumb">
        <img src={fittingDetail?.imageUrl} />
      </div>
      <div className="detail-desc">
        <div className="detail-title">{fittingDetail?.name}</div>
        <div className="detail-line">{fittingDetail?.description}</div>
        <div className="detail-address">
          <span>{fittingDetail?.address}</span>
        </div>
        <div className="detail-call">
          <span>{fittingDetail?.phoneNumber}</span>
        </div>
        <div className="detail-text">
          {fittingDetail?.content?.split("\n").map((txt) => {
            return (
              <>
                {txt}
                <br />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FittingDetail;
