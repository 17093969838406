import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Layer from "../layer";

const Term = () => {
    const navigate = useNavigate();
    const [showLayer, setShowLayer] = useState(false);
    const handleClickNav = () => {
        navigate(-1);
    }
    const handleClickLayer = () => {
        setShowLayer(!showLayer);
    }

    return (
        <>
            <div className="term">
                <div className="sub-header scrolled">
                    <button className="back" onClick={handleClickNav} />
                    <div className="detail-title">R&A 용어</div>
                </div>
                <div className="dc">
                    <div className="dc-title">
                        ㄱ
                        <button className={"unfold"} />
                    </div>
                    <div className="dc-sub" onClick={handleClickLayer}>
                        단어
                    </div>
                    <div className="dc-sub">
                        단어
                    </div>
                    <div className="dc-title">
                        ㄱ
                        <button className={"unfold"} />
                    </div>
                    <div className="dc-sub" onClick={handleClickLayer}>
                        단어
                    </div>
                    <div className="dc-sub">
                        단어
                    </div>
                </div>
                {showLayer && <Layer clickLayer={handleClickLayer} />}
            </div>
        </>
    );
}

export default Term;
