import React from "react";
import { useNavigate } from "react-router-dom";


const Navigator = ({ index }) => {
  const navigate = useNavigate();
  const handleClickNav = (nav) => () => {
    navigate(nav, {
      replace: true
    })
  }
  return (
    <div className="navbar">
      <div className={`nav ${index === 0 && 'active'}`}
        onClick={handleClickNav("/")}>
        <div className="icon root" />
        <div className="icon-text">홈</div>
      </div>
      <div className={`nav ${index === 1 && 'active'}`}
        onClick={handleClickNav("/fitting")}>
        <div className="icon fitting" />
        <div className="icon-text">피팅</div>
      </div>
      <div className={`nav ${index === 2 && 'active'}`}
        onClick={handleClickNav("/board")}>
        <div className="icon gym" />
        <div className="icon-text">그늘집</div>
      </div>
    </div>
  );
}

export default Navigator;
