import { createAction } from "@reduxjs/toolkit";
import { put, call, takeLatest, debounce } from "redux-saga/effects";
import {
  addFittingShop,
  setFittingDetail,
  setBoardNotice,
  setPostsNotice,
  setNewsBanner,
  setFittingCities,
  setFittingSearchList,
} from "./slice";
import axios from "axios";
import { errorHandler } from "../../util/errorUtil";

export const getHome = createAction("home/getHome");
export const getFittings = createAction("home/getFittings");
export const searchFittings = createAction("home/searchFittings");
export const getFittingDetail = createAction("home/getFittingDetail");
export const getPosts = createAction("home/getPosts");
export const addPost = createAction("home/addPost");
export const getNotice = createAction("home/getNotice");
export const getNewsBanner = createAction("home/getNewsBanner");
export const getCities = createAction("home/getCities");

function* getHomeSaga() {
  try {
    yield put(getNewsBanner());
  } catch (error) {}
}

function* getFittingsSaga({ payload }) {
  try {
    const fitting = yield call(
      axios.get,
      `/v1/fittingShops?city=${encodeURIComponent(payload)}`
    );

    yield put(
      addFittingShop({
        [payload]: fitting.data.fittingShops,
      })
    );
  } catch (error) {}
}

function* getCitiesSaga({ payload }) {
  try {
    const fitting = yield call(axios.get, `/v1/fittingShops/cities`);

    yield put(setFittingCities(fitting.data.cities));
  } catch (error) {}
}

function* searchFittingsSaga({ payload }) {
  try {
    const fitting = yield call(
      axios.get,
      `/v1/fittingShops/search?name=${encodeURIComponent(payload)}`
    );

    yield put(setFittingSearchList(fitting.data.fittingShops));
  } catch (error) {}
}

function* getFittingDetailSaga({ payload }) {
  try {
    const fitting = yield call(axios.get, `/v1/fittingShops/${payload}`);

    yield put(setFittingDetail(fitting.data));
  } catch (error) {}
}

function* getPostsSaga() {
  try {
    const posts = yield call(axios.get, `/v1/posts`);

    yield put(setPostsNotice(posts.data.posts));
  } catch (error) {}
}

function* addPostSaga({ payload }) {
  try {
    yield call(axios.post, `/v1/posts`, {
      writer: payload.user.trim(),
      content: payload.content.trim(),
    });

    payload.callback();
  } catch (error) {
    payload.fallback();
    errorHandler(error);
  }
}

function* getNoticeSaga() {
  try {
    const notice = yield call(axios.get, `/v1/notices`);

    yield put(setBoardNotice(notice.data.content));
  } catch (error) {}
}

function* getNewsBannerSaga() {
  try {
    const banner = yield call(axios.get, `/v1/news/banner`);

    yield put(
      setNewsBanner({
        bannerUrl: banner.data.bannerUrl,
        linkUrl: banner.data.linkUrl,
        mainUrl: banner.data.mainUrl,
        colorCode: banner.data.colorCode,
      })
    );
  } catch (error) {}
}

export function* homeSaga() {
  yield takeLatest(getHome, getHomeSaga);
  yield takeLatest(getFittings, getFittingsSaga);
  yield debounce(500, searchFittings, searchFittingsSaga);
  yield takeLatest(getFittingDetail, getFittingDetailSaga);
  yield takeLatest(getPosts, getPostsSaga);
  yield takeLatest(addPost, addPostSaga);
  yield takeLatest(getNotice, getNoticeSaga);
  yield takeLatest(getNewsBanner, getNewsBannerSaga);
  yield takeLatest(getCities, getCitiesSaga);
}
