import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

import { useDispatch, useSelector } from "react-redux";
import {
  addAdminLogo,
  addAdminNewsBanner,
  getAdminLogo,
  getAdminNewsBanner,
} from "../../../redux/admin/saga";
import { adminLogoSelector } from "../../../redux/admin/selector";

const AdminLogo = () => {
  const dispatch = useDispatch();
  const [preview, setPreview] = useState("");
  const [image, setImage] = useState();
  const { imageUrl } = useSelector(adminLogoSelector);
  const handleChangeImage = (event) => {
    const image = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      setPreview(reader.result);
    };
    setImage(image);
  };

  const handleClickSave = () => {
    dispatch(
      addAdminLogo({
        image,
      })
    );
  };

  useEffect(() => {
    dispatch(getAdminLogo());
  }, [dispatch]);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650, marginTop: 2 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: "20%" }}>로고 이미지</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ margin: "20px 20px 20px 0" }}
                >
                  이미지 변경
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleChangeImage}
                  />
                </Button>
                <br />
                {preview ? (
                  <img src={preview} alt="로고" />
                ) : imageUrl ? (
                  <img src={imageUrl} alt="로고" />
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell colSpan={2} align="right" sx={{ border: 0 }}>
                <Button
                  variant="contained"
                  component="label"
                  onClick={handleClickSave}
                >
                  저장
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdminLogo;
